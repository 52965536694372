import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import axios from 'axios'
import { ElMessage } from 'element-plus'

const app = createApp(App)
installElementPlus(app)

//判断是否是开发环境
let ifDEV = process.env.NODE_ENV === 'development'
// console.log(ifDEV);

const baseURL = ifDEV?'http://localhost:4000':''

axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true  //axios发送请求默认是false，即不带cookie的

//全局引入axios   可以通过 每个组件实例的 this.$axios 来访问到axios
app.config.globalProperties.$axios= axios
app.config.globalProperties.$message = ElMessage
app.use(store).use(router).mixin({     //为全局混入一个baseURL，使得所有组件可以通过实例 this.baseURL  获取到 baseURL
    data() {
        return {
            baseURL
        }
    }
}).mount('#app')

