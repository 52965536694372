<template>
  <!-- 导航 -->
  <AppNav></AppNav>

  <!-- 内容部分 -->
  <div id="main">
    <!-- 左侧边栏 -->
    <AppSide></AppSide>

    <router-view></router-view>

    <!-- 隐藏层 -->
    <div v-if="isHidden" class="hidden"></div>

    <!-- 路由页面 -->
  </div>

  <div id="beian">渝ICP备2022000793号</div>

  <div v-show="isShow_rightSetTop" id="right_setTop">︿</div>
</template>

<script>
import AppNav from "@/components/AppNav.vue";
import AppSide from "@/components/AppSide.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "App",
  data() {
    return {
      isShow_rightSetTop: false,
    };
  },
  components: {
    AppNav,
    AppSide,
  },
  computed: {
    ...mapState(["isHidden"]),
  },
  methods: {
    ...mapMutations(["updateUserInfo"]),
    scrollEvent() {
      let scorllTop = document.body.scorllTop || document.documentElement.scrollTop;
      let scrollMaxLength = document.body.scrollHeight-document.documentElement.clientHeight

      if (scorllTop >= 3*scrollMaxLength/5) {
        this.isShow_rightSetTop = true;
      } else {
        this.isShow_rightSetTop = false;
      }
    },
  },
  async created() {
    //检测用户是否登录 session (设置登录可持久化)
    let { data } = await this.$axios({
      method: "post",
      url: "/login/check",
    });

    // console.log(data);

    if (data.code == 0) {
      // console.log(data.data);
      this.updateUserInfo(data.data); //将session值传给vuex中的数据userInfo保存
    }
  },
  mounted() {
    window.addEventListener("scroll", _.debounce(this.scrollEvent,100));
    document.querySelector("#right_setTop").addEventListener("click", () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.isShow_rightSetTop = false;
      });

  },
};
</script>

<style lang='less'>
@import "./assets/css/reset.css";
@import "./assets/css/font.css";
@import "./assets/css/font/iconfont.css";

.clear::before,
.clear::after {
  content: "";
  display: table;
  clear: both;
}
html {
  min-width: 330px;
}
body {
  background:    /* 双背景图片 */ url("./assets/img/bg//bg-left.png") no-repeat
      left 50px,
    url("./assets/img/bg//bg-right.png") no-repeat right 50px;
  background-color: #f2f2f2;
}
#main {
  margin: 61px auto 0;
  padding: 0 8px;
  width: 100%;
  max-width: 1300px;
  box-sizing: border-box;
  display: flex;
  .hidden {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.6);
  }
}

#beian {
  line-height: 50px;
  text-align: center;
}

.el-popper {
  min-width: 100px !important;
  height: 100px;
  z-index: 10 !important;
  &.userPhotoPopper {
    height: 80px;
  }
  &.bqBox {
    height: 150px;
  }
  &.delete {
    width: 120px;
    height: 50px;
    line-height: 25px;
    font-size: 14px;
    color: rgb(105, 105, 105);
  }
}

#right_setTop {
  width: 40px;
  height: 40px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  background: white;
  color: rgba(29, 28, 28, 0.5);
  position: fixed;
  right: 8px;
  bottom: 20px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(105, 105, 105, 0.5);
  user-select: none;
  cursor: pointer;
}
#right_setTop:hover {
  background: rgb(224, 224, 224);
}

/*自定义滚动条*/
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
  // display: none;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 167, 232, 0.3);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
